<script lang="ts">
    import type { EmblaCarouselType } from 'embla-carousel';

    import EmblaCarousel from 'embla-carousel';
    import { onMount } from 'svelte';
    import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures';
    import type { OptionsType } from 'embla-carousel/components/Options';
    import ProjectSlide from './ProjectSlide.svelte';
    import type { ProjectSlideFragment } from '$gen/graphql';

    export let slides: ProjectSlideFragment[] = [];

    export let lazy = true;
    export let currentIndex = 0;
    export let isHotspotSlider = false;

    let emblaNode: HTMLDivElement;
    let embla: EmblaCarouselType;
    let canScrollNext: boolean;
    let canScrollPrev: boolean;

    $: if (embla) handleChange(); // init prev and next buttons
    $: if (emblaNode) {
        innerHeight = emblaNode.offsetHeight;
    }
    onMount(() => {
        const options: Partial<OptionsType> = { loop: true, align: 'center' };
        embla = EmblaCarousel(emblaNode, options, [WheelGesturesPlugin()]);
        embla.on('select', handleChange);
        return () => {
            if (embla) embla.destroy();
        };
    });

    function handleChange() {
        canScrollNext = embla.canScrollNext();
        canScrollPrev = embla.canScrollPrev();
        currentIndex = embla.selectedScrollSnap();
    }

    export function setIndex(index: number) {
        embla.scrollTo(index);
    }

    /** TS-Stuff */
    type SshAsset = Extract<
        ProjectSlideFragment['media'][number],
        { __typename: 'studioShAssets_Asset' }
    >;
    const isSshAsset = (item: ProjectSlideFragment['media'][number]): item is SshAsset => {
        return (item as SshAsset).__typename === 'studioShAssets_Asset';
    };
</script>

<section class="flex flex-col md:flex-row">
    <!-- embla -->
    <div class="relative h-full w-full" style={`cursor: ${slides.length > 1 ? 'grab' : 'default'}`}>
        <!-- embla-viewport -->
        <div
            bind:this={emblaNode}
            class="embla__viewport h-full max-h-full overflow-hidden bg-transparent"
        >
            <!-- embla-container -->
            <div class="embla__container flex h-full">
                {#each slides as slide}
                    <!-- embla-slide -->
                    <div class="embla__slide relative m-0 h-full max-h-full">
                        {#key slides}
                            {#each slide.media.filter(isSshAsset) as slide}
                                <ProjectSlide {slide} />
                            {/each}
                        {/key}
                    </div>
                {/each}
            </div>
        </div>
        {#if slides.length > 1}
            {#if canScrollPrev}
                <button
                    id="prev"
                    class="absolute top-0 hidden h-full w-1/2 cursor-w-resize p-5 uppercase md:block md:text-2xl"
                    on:click={() => embla.scrollPrev()}
                    disabled={!canScrollPrev}
                    class:disabled={!canScrollPrev}
                >
                    <!-- <div class="icon">Back</div> -->
                </button>
            {/if}
            {#if canScrollNext}
                <button
                    id="next"
                    class="absolute top-0 right-0 hidden h-full w-1/2 cursor-e-resize p-5 uppercase md:block md:text-2xl"
                    on:click={() => embla.scrollNext()}
                    disabled={!canScrollNext}
                    class:disabled={!canScrollNext}
                >
                    <!-- <div class="icon">Next</div> -->
                </button>
            {/if}
        {/if}
    </div>
    <div
        class="description absolute bottom-0 left-0 space-y-5 p-5 text-xl leading-5 text-white md:text-2xl md:leading-6"
        style:color={slides[currentIndex].descriptionColor ?? 'white'}
    >
        {@html slides[currentIndex]?.description}
    </div>
</section>

<style lang="scss">
    .embla__slide {
        --slide-width: 100%;
        flex: 0 0 var(--slide-width);
    }

    section {
        .description :global(a) {
            @apply text-blue;
        }
    }
</style>
