<script lang="ts">
    import type { ProjectFragment } from '$gen/graphql';
    import lazyimage from '$lib/actions/lazyImage';

    export let slide: ProjectSlideMediaType;

    type ProjectSlideMediaType = Extract<
        ProjectFragment['projectSlider'][number]['media'][number],
        { __typename: 'studioShAssets_Asset' }
    >;
</script>

{#if slide.kind === 'json'}
    <div class="pointer-events-none object-contain lg:object-cover">
        {@html slide.embeddedAsset.html}
    </div>
{:else if slide.kind === 'image'}
    <img
        class="pointer-events-none h-screen w-full object-contain lg:object-cover"
        use:lazyimage={{ useLazyLoading: true, size: 1.0 }}
        data-src={slide.url}
        data-srcset={slide.srcset}
        alt={slide.title}
    />
{/if}

<style>
    :global(iframe) {
        max-height: 100vh;
        width: 100%;
    }
</style>
