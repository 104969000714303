<script lang="ts">
    import type { ProjectFragment, ProjectSlideFragment } from '$gen/graphql';
    import { fly, slide } from 'svelte/transition';

    export let project: ProjectFragment;

    export let open = false;

    $: slides = project?.projectSlider || [];
    // $: console.log({ project });

    /** TS-Stuff */
    type StudioSHAsset = Extract<
        ProjectSlideFragment['media'][number],
        { __typename: 'studioShAssets_Asset' }
    >;
    const isStudioSHAsset = (item): item is StudioSHAsset => {
        return (item as StudioSHAsset).__typename === 'studioShAssets_Asset';
    };
</script>

{#if project}
    <article id={project.title}>
        <!-- Other project slides in collapsible -->
        {#if open}
            <div transition:slide|local class="grid grid-flow-row-dense grid-cols-1">
                {#each slides as slide_}
                    {#each slide_.media.slice(0, 1).filter(isStudioSHAsset) as media}
                        {#if media.kind === 'json'}
                            <div class="embeddedAsset aspect-video">
                                {@html media.embeddedAsset.html}
                            </div>
                        {:else if media.kind === 'image'}
                            <img
                                src={media.url}
                                srcset={media.srcset}
                                alt={media.title}
                                width={media.width}
                                height={media.height}
                            />
                        {/if}
                    {/each}
                {/each}

                <!-- Last slide with project info -->
                <div class="aspect-video p-5 text-xl leading-5 md:text-2xl md:leading-6">
                    <p class="">Project Info:</p>
                    <h2>{project.title}</h2>
                    <div class="redactor mb-5">
                        {@html project.mobileDescription ?? ''}
                    </div>
                </div>
            </div>
        {/if}
    </article>
{/if}

<style lang="scss">
    .embeddedAsset {
        :global(iframe) {
            width: 100%;
            height: 100%;
        }
    }

    .redactor {
        @apply mt-3;
        :global(p + p) {
            @apply mt-2;
        }

        :global(a) {
            @apply text-blue;
        }
    }
</style>
