<script lang="ts" context="module">
    import type { Load } from '@sveltejs/kit';
    export const load: Load = async ({ stuff, params }) => {
        const query = await stuff.cms.project({ slug: params.project });
        return {
            props: {
                query,
            },
        };
    };
</script>

<script lang="ts">
    import type { ProjectQuery } from '$gen/graphql';
    import ProjectSlider from '$lib/components/carousel-project-slider/ProjectSlider.svelte';
    import ProjectMobileVersion from '$lib/components/project/ProjectMobileVersion.svelte';

    export let query: ProjectQuery;

    $: project = query?.project?.__typename === 'projects_default_Entry' ? query.project : null;
</script>

{#if project}
    <!-- Desktop version with slides in a slider -->
    <div class="hidden lg:block">
        {#key project.id}
            <ProjectSlider slides={project.projectSlider} />
        {/key}
    </div>

    <!-- Mobile version with slides in one column  -->
    <div class="h-full overflow-y-auto lg:hidden">
        <ProjectMobileVersion {project} open={true} />
    </div>
{/if}
